import React, { Suspense } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
// CSS
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'react-day-picker/dist/style.css'
import "./date-picker-custom.css"
// MATERIAL UI
import { green } from '@mui/material/colors'
import { ThemeProvider, createTheme } from '@mui/material/styles'
// COMPONENTS
import LoadingComponent from './components/commons/LoadingComponent'
import { Switch } from '@mui/material'

const ScrollToTopAfterChangePage = React.lazy(() => import('./components/utils/ScrollToTop'))
const InitializeCookies = React.lazy(() => import('./components/utils/InitializeCookies'))
// COMPONENTS
const AfterNego = React.lazy(() => import('./components/utils/AfterNego'))
const Layout = React.lazy(() => import('./components/layout/Layout')) 
const NavigateToNewRoute = React.lazy(() => import('./components/utils/old_router/NavigateToNewRoute'))

// SCREENS (PAGES)
const CekProfilKredit = React.lazy(() => import('./screens/cairin_bpkb/CekProfilKredit'))
const KreditKendaraanBermotor = React.lazy(() => import('./screens/djubli_kredit/kredit_kendaraan_bermotor/KreditKendaraanBermotor'))
const ShowPerCategory = React.lazy(() => import('./screens/buy_car/ShowPerCategory'))
const AboutUs = React.lazy(() => import('./screens/about_djubli/AboutUs'))
const ContactUs = React.lazy(() => import('./screens/about_djubli/ContactUs'))
const PageNotFound = React.lazy(() => import('./components/utils/PageNotFound'))
const SellCar = React.lazy(() => import('./screens/sell_car/SellCar'))
const HomeScreen2 = React.lazy(() => import('./screens/HomeScreen2'))
const BuyCarScreen =  React.lazy(() => import('./screens/buy_car/BuyCarScreen'))
const DealerProfile = React.lazy(() => import('./screens/DealerProfile'))
const FormCairinBpkb = React.lazy(() => import('./screens/cairin_bpkb/FormCairinBpkb'))
const DanaTunaiSimulation = React.lazy(() => import('./screens/cairin_bpkb/DanaTunaiSimulation'))
const SuccessSubmitForm = React.lazy(() => import('./screens/cairin_bpkb/SuccessSubmitForm'))
const RegisterUser = React.lazy(() => import('./screens/user/RegisterUser'))
const PrivacyPolicy = React.lazy(() => import('./screens/PrivacyPolicy'))
const EditProfile = React.lazy(() => import('./screens/user/settings/EditProfile'))
const UserProfileMenu = React.lazy(() => import('./screens/user/UserProfileMenu'))
const MyListingCar = React.lazy(() => import('./screens/sell_car/MyListingCar'))
const DjuBliCheckHome = React.lazy(() => import('./screens/djubli_check/DjuBliCheckHome'))
const DetailCarNew = React.lazy(() => import('./screens/buy_car/DetailCarNew'))
const DjubliKreditHome = React.lazy(() => import('./screens/djubli_kredit/DjubliKreditHome'))
const DjubliMarketHome = React.lazy(() => import('./screens/djubli_market/DjubliMarketHome'))
const DjubliMarketChart = React.lazy(() => import('./screens/djubli_market/DjubliMarketChart'))
const ChangePassword = React.lazy(() => import('./screens/user/settings/ChangePassword'))
const DeleteAccount = React.lazy(() => import('./screens/user/settings/DeleteAccount'))
const DjubliPromoPage = React.lazy(() => import('./screens/promo_program/DjubliPromoPage'))
const UploadDokumen = React.lazy(() => import('./screens/cairin_bpkb/UploadDokumen'))
const AjukanKredit = React.lazy(() => import('./screens/promo_program/AjukanKredit'))
const AllCarsList = React.lazy(() => import('./screens/promo_program/AllCarsList'))
const KKBSimulationScreen = React.lazy(() => import('./screens/djubli_kredit/kredit_kendaraan_bermotor/KKBSimulationScreen'))
const SuccessApplyCredit = React.lazy(() => import('./screens/promo_program/SuccessApplyCredit'))
const DjuBliAuctionHome = React.lazy(() => import('./screens/djubli_auction/DjuBliAuctionHome'))
const ShowPerCategoryAuction = React.lazy(() => import('./screens/djubli_auction/ShowPerCategoryAuction'))
const AuctionDetail = React.lazy(() => import('./screens/djubli_auction/AuctionDetail'))
const AuctionHall = React.lazy(() => import('./screens/djubli_auction/AuctionHall'))
const BidRoom = React.lazy(() => import('./screens/djubli_auction/BidRoom'))
const CnafAuction = React.lazy(() => import('./screens/djubli_auction/CnafAuction'))
const CnafHowTo = React.lazy(() => import('./screens/djubli_auction/CnafHowTo'))
// const DjubliMember = React.lazy(() => import('./screens/about_djubli/DjubliMember'))
// const CreditSimulationNonListing = React.lazy(() => import('./screens/djubli_kredit/CreditSimulationNonListing'))
// const PriceEstimation = React.lazy(() => import('./screens/sell_car/PriceEstimation'))
// const FirstStepSell = React.lazy(() => import('./screens/sell_car/listing/FirstStepSell'))
// const ListingScreen = React.lazy(() => import('./screens/sell_car/listing/ListingScreen'))
// const DealerScreen = React.lazy(() => import('./screens/dealer/DealerScreen'))
// const DealerPerCategory = React.lazy(() => import('./screens/dealer/DealerPerCategory'))

// BUAT CUSTOM DEFAULT THEME MATERIAL UI
const theme = createTheme({
  palette: {
    primary: { main: '#00937C' },
    secondary: { main: green[500] },
    whiteBackground: { main: '#f9f9f9' },
    whatsAppColor: { main: '#22A515' },
    mainFont: { main: '#3A3C51' },
    yellowSellButton: { main: '#FFCE31' },
    djubliMarket:{ main: '#5B82CF' },
    djubliKKB: { main: '#BE63F5' },
    djubliCheck: { main: '#F2575D' }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm3: 390,
      sm2: 460,
      sm1: 550,
      sm: 600,
      md2: 700,
      md: 900,
      lg1: 1030,
      lg2: 1100,
      lg: 1200,
      sxl: 1385,
      xl: 1536,
      xxl: 1700
    }
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'sxl'
      }
    },
  }
});

const App = () => {
  return (
    <>
      <Router>

        <Suspense fallback={<LoadingComponent height={"100vh"} />}>
          <InitializeCookies />
          <ScrollToTopAfterChangePage /> 
          <ThemeProvider theme={theme}>
              <Layout>
                <Routes>
                    <Route exact path="/" element={<CnafAuction />} />
                    <Route exact path='/cnaf-cara-ikut-lelang' element={<CnafHowTo />}></Route>

                    <Route exact path="/cari-mobil" element={<Navigate to={'/cari-mobil-bekas-baru'} replace={true} />} />
                    <Route exact path="/cari-mobil-bekas-baru" element={<BuyCarScreen />} />
                    <Route exact path="/jual-beli-mobil-bekas" element={<ShowPerCategory />} /> 
                    <Route exact path="/jual-beli-mobil-bekas/:merk" element={<ShowPerCategory />} />
                    <Route exact path="/jual-beli-mobil-bekas/:merk/:groupModel" element={<ShowPerCategory />} />
                    <Route exact path="/jual-beli-mobil-bekas/:merk/:groupModel/:modelYear" element={<ShowPerCategory />} />
                    <Route exact path="/jual-beli-mobil-bekas/:merk/:groupModel/:modelYear/:model" element={<ShowPerCategory />} />
                    <Route exact path="/jual-beli-mobil-bekas/:merk/:groupModel/:modelYear/:model/:carId" element={<DetailCarNew />} />

                    {/* ROUTE LAMA - HALAMAN CARI MOBIL */}
                    {/* DI-REPLACE MENGGUNAKAN URL BARU */}
                    <Route exact path="/beli-mobil" element={<Navigate to="/jual-beli-mobil-bekas" replace={true} />} />
                    <Route exact path="/beli-mobil/:merk" element={<NavigateToNewRoute />} />
                    <Route exact path="/beli-mobil/:merk/:groupModel" element={<NavigateToNewRoute />} />
                    <Route exact path="/beli-mobil/:merk/:groupModel/:modelYear" element={<NavigateToNewRoute />} />
                    <Route exact path="/beli-mobil/:merk/:groupModel/:modelYear/:model" element={<NavigateToNewRoute />} />
                    <Route exact path="/beli-mobil/:merk/:groupModel/:modelYear/:model/:carId" element={<NavigateToNewRoute />} />

                    <Route path='*' element={<PageNotFound />} />
                  
                </Routes>
              </Layout>
          
          </ThemeProvider>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
